@import '~bf-solid/_lib/solid-helpers/variables';
@import '~bf-solid/_lib/solid-helpers/mixins';
@import '~@buzzfeed/adlib/core/sass/mixins';
@import '~@buzzfeed/adlib/core/sass/variables';

@import "~@buzzfeed/adlib/components/awareness/awareness";
@import "~@buzzfeed/adlib/components/toolbar/toolbar";

.Ad {
  @import '~@buzzfeed/adlib/core/sass/base';
  @import '~@buzzfeed/adlib/components/bigstory/bigstory';
  @import '~@buzzfeed/adlib/components/ex/ex';
  @import '~@buzzfeed/adlib/components/feed-story/feed-story';
}

.Ad--awareness {
  @extend %awareness-mobile-sticky;
  z-index: 900;

  .ad-wireframe-wrapper {
    min-height: unset !important;
  }
}

.Ad--top-awareness,
.Ad--bottom-awareness {
  --height: 50px;

  min-height: calc(var(--height, 50px) + 0.5rem + 0.6rem);
  padding: 0.5rem 0 0.5rem;

  display: flex;
  align-items: center;

  .ad-flexible {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ad-wireframe-wrapper {
    padding: 0;
  }

  &.sticky {
    z-index: 600;
  }

  @include solid-breakpoint('md') {
    display: none;
  }
}

.Ad--feed {
  width: 100%;
  margin: $space-2 auto;

  @include solid-breakpoint('md') {
    margin-top: $space-1;
    margin-bottom: $space-4;
    max-width: 728px;
  }

  @include solid-breakpoint('sm') {
    .ad-flexible--bfp_promo.ad-feed-story.ad-flexible--media-img .ad-awareness-content {
      padding: 1rem;
    }
  }
}

.Ad--section-wide {
  width: 100%;

  .ad-feed-story {
    margin: 0 0 $space-2;
    width: 100%;
  }

  @include solid-breakpoint('md') {
    max-width: 728px;

    .bfp-spotlight--wide.is-jumbo {
      height: 250px;
    }
  }

  @include solid-breakpoint('lg') {
    margin: $space-2 auto $space-4;
    max-width: 960px;

    .ad-feed-story {
      margin: 0 auto;
      max-width: 960px;
    }
  }

  .ad-wireframe-wrapper:not(.ad-wireframe-wrapper--nostick) {
    .ad--loaded:not(.ad-card),
    .ad-ex:not(.ad-card) {
      padding-top: $space-2;
    }
    .ad--loaded {
      margin: 0 auto;
    }
  }

  &.bottom-feed {
    @include solid-breakpoint('md') {
      padding: 1rem 0;
    }
  }
}

/* there's a bug with some programmatic showing 2x disclosures.
  this is a patch for now on the homepage. */
.ad-feed-story {
  .ad__disclosure--programmatic:not(:first-child) {
    display: none;
  }
}

.Ad .ad-flexible--buzz {
  @include solid-breakpoint-before('lg') {
    &.card {
      border: none;
      box-shadow: none;
    }

    .ad-buzz-thumbnail,
    .ad-buzz-content {
      float: none;
      width: auto;
    }

    .ad-buzz-thumbnail {
      flex: 1;
    }

    .ad-buzz-content {
      flex: 2;
      padding: 0;
      margin-left: $space-2;
    }
  }
}

.Ad--sidebar {
  &.Ad--unfilled,
  &.Ad--loading {
    max-width: 300px;
    margin: 0 auto;
    box-shadow: 0 1px 1px rgba(173, 168, 168, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }

  .ad-wireframe {
    margin-bottom: 0;
  }

  .ad__disclosure--ex {
    display: none;
  }

  // Overwrite adlib styles
  &.Ad .ad-bigstory {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

.Ad.Ad--section-wide .ad-card {
  max-width: 800px;
}


.ad-placeholder {
  min-height: 250px;

  margin: 3rem auto;

  // reset these styles defined in adlib
  .Ad {
    margin: 0 auto;

    .ad-flexible {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .Ad--awareness {
    background-color: unset;
  }

  .ad-wireframe-wrapper {
    min-height: unset !important;
  }

  .ad-wireframe-image {
    background-color: #f4f4f4 !important; // adlib sets this to white with `!important`
  }

  &.Ad--section-awareness-wrapper {
    background: #fff;
    margin-left: $space-2;
    margin-right: $space-2;

    @include solid-breakpoint('md') {
      margin-left: auto;
      margin-right: auto;
      background-color: #f4f4f4;
    }

    @include solid-breakpoint('lg') {
      min-height: calc(250px + 2rem);
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &:not(.Ad--section-awareness-wrapper) {
    @include solid-breakpoint('md') {
      min-height: 90px;

      .Ad--unfilled,
      .Ad--loading {
        height: 90px;
      }

      .ad-wireframe-image {
        padding-top: 0;
        height: 100%;
      }
    }
  }

  &.ad-placeholder-section{
    @include solid-breakpoint('lg') {
      min-height: 250px;

      .Ad--unfilled,
      .Ad--loading {
        height: initial;
      }

      .ad-wireframe-image {
        padding-top: 66.4%;
      }
    }
  }

  .Ad--unfilled,
  .Ad--loading {
    .ad-feed-story {
      border-radius: 0.5rem;
      overflow: hidden;
      max-height: 100%;
    }
  }
}

.ad-placeholder-section {
  .Ad--unfilled,
  .Ad--loading {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.ad-sticky-within-placeholder {
  position: sticky !important;
  // The homepage will define `--fixed-elements-offset-bottom` which is equal to the height of the
  // tab navigation
  top: calc(var(--ad-sticky-top, 0px) + var(--fixed-elements-offset-bottom, 0px));
  z-index: 1;
}

.Ad--section-awareness-wrapper:has(.temp-sticky) {
  @include solid-breakpoint("md") {
    min-height: 282px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    position: sticky;
    top: -316px;
  }
}
.Ad--section-awareness-wrapper:has(.temp-sticky.ad--rendered) {
  @include solid-breakpoint("md") {
    top: 0;
  }
}
.Ad--section-awareness-wrapper:has(.temp-sticky.temp-sticky--hide) {
  @include solid-breakpoint("md") {
    top: -316px;
    transition: top 1s;
  }
}
