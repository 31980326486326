@import 'bf-solid/_lib/solid';
@import 'variables';
@import 'fonts';
@import 'carousel';

html {
  overflow-x: hidden;
  @include solid-breakpoint('md') {
    overflow-x: initial;
  }

  &.sponsorship-takeover {
    body {
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--sponsorshipTabBackground, initial);
        z-index: -1;
        opacity: 0;
        transition: opacity 0.6s;
      }
      &:has([data-content-tab-name='sponsored'][data-content-transition-status='active']):before {
        opacity: 1;
      }
      #js-header-container #js-bfo-logo-title + use,
      #js-header-container #buzzfeed + use {
        // svg logo image does not have default fill color
        fill: var(--sponsorshipLogoColor, #e32);
      }
    }

    @include solid-breakpoint('lg') {
      .feed-content-area {
        &:has(+ .ad-placeholder) {
          padding-bottom: 3rem;
        }

        &.ad-placeholder {
          margin: 0 auto;
        }
      }
    }
  }
}

body {
  text-wrap: pretty; // Progressive enhancement: provides better line breaks (preventing hanging words)
  line-height: unset;

  @include solid-breakpoint('lg') {
    background: var(--sponsorshipBackground, initial);
    cursor: var(--sponsorshipCursor, auto);
  }
}

// Using `where` so that `.feed-content` does not add to the specificity of the selectors
:where(.feed-content-area) {
  --color-accent-pink: #EB5369;
  --color-background-blue: #B1ECFD;
  --color-background-green: #F0FA81;
  --color-error-dark: #B71A22;
  --color-error-light: #FABFB7;
  --color-primary-line: #E0E0E0;
  --color-purple-primary: #5246F5;
  --color-purple-secondary: #CDC9FF;
  --color-success-dark: #618910;
  --color-success-light: #CCE5B3;

  background-color: #fff;
  margin: 0 auto;

  h1, h2, h3, h4 {
    font-family: Calistoga, cursive;
    font-weight: 400;
    letter-spacing: 0.01em;
  }

  h3, h4 {
    margin: $space-1 0;
  }

  h4 {
    font-family: inherit;
    font-weight: $bold;
  }

  a {
    color: inherit;

    &:hover {
      color: var(--color-purple-primary);
    }
  }

  button {
    background: none;
    border: none;
  }

  &.home-page {
    padding-top: $space-5;
  }

  &.standard-page {
    margin-top: $space-3;
  }
}

// toDo: remove this
.tmp-topic-main-feed {
  height: 1800px;
}

@include solid-breakpoint('md') {
  :where(.feed-content-area) {
    // including the left/right padding
    max-width: $content-max-width;

    &.topic-page {
      max-width: 1240px;
    }
  }
}

/*
  The css var `--fixed-elements-offset-bottom` is used to ensure other elements that are fixed to the
  bottom get placed above the homepage feed tab navigation bar. This is necessary because the homepage
  feed tab navigation bar is fixed to the bottom of the viewport.
*/
.Ad div.ad-toolbar {
  bottom: var(--fixed-elements-offset-bottom, 0px);

  @include solid-breakpoint('md') {
    bottom: 0;
  }
}

/*
  If there is an ad before the main home page content, remove the Ad bottom margin since the main
  content below will have padding to separate it from the ad.
*/
.ad-placeholder:has(+ .home-page) {
  margin-bottom: 0;
}

.screen-reader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
