.react-slideshow-container {
  position: relative;
  .react-slideshow-fadezoom-wrapper {
    width: 100%;
    overflow: hidden;
  }
  .react-slideshow-fadezoom-images-wrap {
    display: flex;
    > div {
      position: relative;
      opacity: 0;
      display: flex !important;
      width: 100%;
      flex-shrink: 0;
    }
  }
  .btnPaging {
    position: absolute;
    left: calc(53.8% - 73px);
    top: 100%;
    background: none;
    width: 24px;
    height: 24px;
    border: none;
    margin-top: 8px;
    padding: 0;
    svg {
      display: block;
      width: 100%;
      height: 100%;
      circle {
        fill: $color-feed-purple;
        transition: fill 0.3s ease-in-out;
      }
    }
    &:hover {
      svg {
        circle {
          fill: $color-feed-pink ;
        }
      }
    }
  }
  .btnNext {
    left: calc(53.8% - 41px);
    svg {
      transform: rotate(180deg);
    }
  }
}
.react-slideshow-container + .indicators {
  list-style: none;
  display: flex;
  align-items: center;
  padding: $space-05 0 14px;
  width:  53.8%;
  .indicator {
    width: 14px;
    height: 14px;
    position: relative;
    margin-right: 6px;
    cursor: pointer;
    .icon {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      display: block;
    }
    &.active {
      .icon {
        opacity: 1;
      }
    }
    &:not(.active):before {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      width: 12px;
      height: 12px;
      border: 1px solid $color-feed-purple;
      border-radius: 50%;
      transition: border-color 0.3s ease-in-out;
    }
    &:hover {
      &:before {
        border-color: $color-feed-pink;
      }
    }
  }
}
